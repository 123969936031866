import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BOOKING_TYPES } from "../../common/config";
import { BookingSteps, CalendarFms, CampSteps } from "../index";
import TrainerDetails from "./components/TrainerDetails";
import FrequencyHelper from "../../utility/FrequencyHelper";
import {
  calculateFMQuantity,
  calculateIsPackageNeeded,
  campPackDefault,
  campPacksList,
  getClassPayload,
  getMultiClassPackages,
  getRemainingForClass,
  getRemainingForSessions,
  getSessionFm,
  getSessionPayload,
  getVerifyCoupon,
  isCreditsAvailableSession,
  isSubmitDisabled,
  validateCampAnsweredQuestions,
} from "./utils";
import FamilyMembers from "./components/FamilyMembers";
import CampQuestions from "./components/CampQuestions";
import BookingDateTimeUI from "./components/BookingDateTimeUI";
import CampPacks from "./components/CampPacks";
import CouponCodeUI from "./components/CouponCodeUI";
import {
  addSelectedPackage,
  changeClientPage,
  clearAppointmentBookingDetails,
  clearClassBookingDetails,
  clearPublicUser,
  verifyCouponCode,
} from "../../redux/actions";
import { useHistory } from "react-router-dom";
import PackUI from "./components/PackUI";
import {
  AxiosHelper,
  AxiosHelperPortal,
  showToast,
} from "../../utility/helperFunctions";
import { BASE_URL_WEB, BASE_URL_V2 } from "../../utility/constants";
import { getBookingConfirmationPageData } from "../../pages/afterLogin/PostBookingConfirmation/utils";
import _ from "lodash";
import RepeatClassesUI from "./components/RepeatClassesUI";
import InfoItem from "../../pages/afterLogin/PostBookingConfirmation/components/InfoItemUI";
/**
 * !class rebook, existing member should be selected. book new members
 *  */
const ConfirmBooking = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bookingDetails = useSelector((state) => state.booking);
  const authDetails = useSelector((state) => state.auth);
  const { isFromCheckIn } = useSelector((state) => state.publicUser) || {};

  const bookingType = bookingDetails?.classDetails
    ? BOOKING_TYPES.CLASS
    : BOOKING_TYPES.APPOINTMENT;
  const isCamp = bookingDetails?.classDetails?.isCamp;
  const haveKids = bookingDetails?.classDetails?.haveKids;
  const isPrivateClass = bookingDetails?.classDetails?.access === "private";

  const repeatIdClasses = authDetails.companyData.calendar?.filter(
    (classItem) =>
      bookingDetails?.classDetails?.repeat_id && //! repeat_id should not be null/undefined
      bookingDetails?.classDetails?.repeat_id === classItem?.repeat_id
  );
  const isRepeatClass = repeatIdClasses?.length > 0 && isCamp;
  const { camp_questions, group_class, multi_class_pack } =
    authDetails.companyData.services || {};

  const selectedServiceQuestion = group_class?.filter(
    ({ uuid }) => bookingDetails?.classDetails?.serviceId === uuid
  )?.[0]?.questions;
  const Questions = camp_questions?.filter((campQues) =>
    selectedServiceQuestion?.find((quesId) => campQues.uuid === quesId)
  );
  const campQuestionsWithOrder = Questions?.map((Ques) => ({
    ...Ques,
    order: selectedServiceQuestion?.indexOf(Ques.uuid),
  }));
  const campQuestions = campQuestionsWithOrder?.sort(
    (a, b) => a.order - b.order
  );

  const isBookedIsCampNoBooking =
    bookingDetails?.classDetails?.isBooked &&
    isCamp &&
    "Please contact coach to add more family members";

  const [remainingSession, setRemainingSession] = useState({
    count: 0,
    showPackage: Boolean(bookingDetails?.selectedPackage),
    membership_pack: null,
    cards: [],
    isQuantityUnlimited: false,
  });
  let setPreSelectedPack = bookingDetails?.selectedPackage;
  if (!bookingDetails?.selectedPackage && isCamp) {
    setPreSelectedPack = campPackDefault({ bookingDetails });
  }
  const allClassList = useMemo(
    () =>
      getMultiClassPackages({
        bookingType,
        bookingDetails,
        multi_class_pack,
        group_class,
      }),
    []
  );

  //! keep
  const [multiClassId, setMultiClassId] = useState(null);
  const [couponCode, setCouponCode] = useState({
    userCode: "",
    loading: false,
  });
  const [loading, setLoading] = useState({
    isSubmitBtn: false,
  });
  //! keep

  const selectedPack = setPreSelectedPack;
  //! states !//

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !authDetails.companyDataLoadingStatus &&
      !bookingDetails?.selectedPackage
    ) {
      remainingSessions();
    }
  }, [authDetails.companyDataLoadingStatus, bookingDetails?.selectedPackage]);

  const remainingSessions = async () => {
    if (bookingType === BOOKING_TYPES.CLASS && isCamp) {
      setRemainingSession((p) => ({ ...p, showPackage: false }));
      setLoading((p) => ({ ...p, isSubmitBtn: false }));
      return;
    }
    let response;
    setLoading((p) => ({ ...p, isSubmitBtn: true }));
    if (bookingType === BOOKING_TYPES.APPOINTMENT) {
      response = await getRemainingForSessions({
        authDetails,
        bookingDetails,
      });
    } else if (bookingType === BOOKING_TYPES.CLASS) {
      response = await getRemainingForClass({
        classDetails: bookingDetails?.classDetails,
      });
    }
    response && setRemainingSession(response);
    setLoading((p) => ({ ...p, isSubmitBtn: false }));
  };

  const _handleValidateCouponCode = async () => {
    setCouponCode((p) => ({ ...p, loading: true }));
    let serviceId='';
    // if bookType is appointment then serviceId=bookingDetails?.service?.uuid
    if(bookingType === BOOKING_TYPES.APPOINTMENT)
    {
      serviceId=bookingDetails?.service?.uuid;
    }else{
      serviceId=bookingDetails?.classDetails?.serviceId;
    }

    const response = await getVerifyCoupon(couponCode?.userCode,serviceId,bookingType);
    setCouponCode((p) => ({
      ...p,
      loading: false,
    }));
    dispatch(
      verifyCouponCode({
        couponDetails: response,
        isValid: Boolean(response),
      })
    );
  };

  const _handleOnClickBack = () => {
    if (bookingType === BOOKING_TYPES.APPOINTMENT) {
      dispatch(changeClientPage(bookingDetails?.trainer));
      return;
    }
    history.replace("/class-booking");
    dispatch(clearClassBookingDetails());
  };

  //! camp booking
  const isClassBookingValid = () => {
    if (haveKids && !bookingDetails?.selectedFamilyMembers?.length) {
      showToast.error(
        "You need to select at-least one family member! Please contact your coach."
      );
      return;
    }
    const questionErr =
      validateCampAnsweredQuestions({
        haveKids,
        selectedFamilyMembers: bookingDetails?.selectedFamilyMembers,
        campQuestions,
      }) || "";
    if (questionErr !== "") {
      showToast.error(questionErr);
      return;
    }
    return true;
  };

  const campBooking = () => {
    if (!isClassBookingValid()) {
      return;
    }
    const payload = getClassPayload({
      selectedPack,
      bookingType,
      bookingDetails,
      authDetails,
      coupon: bookingDetails?.couponCode?.couponDetails,
      isCamp,
      multiClassId,
      group_class,
    });
    console.log("===========");
    console.log("payload", payload);
    console.log("===========");
    _navToPaymentPage(payload);
  };
  const classPackageBuy = () => {
    if (!isClassBookingValid()) {
      return;
    }
    const payload = getClassPayload({
      selectedPack,
      bookingType,
      bookingDetails,
      authDetails,
      coupon: bookingDetails?.couponCode?.couponDetails,
      isCamp,
      multiClassId,
      group_class,
      packQuantityFamilyMember: bookingDetails?.classDetails?.haveKids
        ? calculateFMQuantity({
            bookingDetails,
            packageQuantity: selectedPack?.quantity,
            remainingSession,
          })
        : 1,
    });
    payload.from = "clp";
    console.log("payloadpayload23", payload);
    if (calculateIsPackageNeeded({ remainingSession, bookingDetails })) {
      _navToPaymentPage(payload);
    } else {
      classAddWithCredit(payload);
    }
  };

  const classAddWithCredit = async (payload) => {
    setLoading({ isSubmitBtn: true });
    payload = _.omit(payload, [
      "payment_method",
      "price",
      "packageLabel",
      "quantity",
    ]);
    console.log("===========");
    console.log("payload", payload);
    console.log("===========");

    const url = BASE_URL_V2 + "user-add-member-to-class?";
    try {
      const response = await AxiosHelper(
        url,
        "post",
        authDetails.companyData.member.id,
        "member",
        payload
      );
      if (response.status === 200) {
        const url = `${BASE_URL_WEB}client-class-member-service?serviceId=${
          response?.data?.booked_appointment?.serviceId
        }&noCards=${true}`;
        try {
          const res = await AxiosHelperPortal(url, "get");
          const responseData = {
            order: response?.data?.order,
            booked_appointment: response?.data?.booked_appointment,
            subscription: response?.data?.subscription,
            member: authDetails?.companyData?.member,
            membership_packs: res?.data?.membership_pack,
            data: { ptRemaining: res?.data?.ptRemaining },
            doNotShowOrder: "true",
            bookedWithCredits: "true",
            unlimited: res?.data?.unlimited,
          };
          _moveToBookingConfirmationPage(responseData);
          dispatch(clearPublicUser());
        } catch (error) {
          console.log(error, error.res);
        }
      } else {
        showToast.error("Something went wrong!", "Sorry!");
        setTimeout(() => {
          history.replace("/class-booking", {
            start: payload?.start,
          });
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      showToast.error(error.response?.data?.message, "Sorry!");
      setTimeout(() => {
        history.replace("/class-booking", {
          start: payload?.start,
        });
      }, 3000);
    }
  };

  const _navToPaymentPage = (payload) => {
    if (bookingType === BOOKING_TYPES.APPOINTMENT) {
      history.push("/payment", {
        package: selectedPack,
        payload,
        bookingType: "appointment",
      });
      return;
    }
    history.push("/payment", {
      package: selectedPack,
      familyMembers: bookingDetails?.classDetails?.familyMembers,
      selectedFamilyMembers: bookingDetails?.selectedFamilyMembers || [],
      payload,
      bookingType,
    });
  };

  const appointmentPackageBuy = () => {
    const payload = getSessionPayload({
      selectedPack,
      bookingDetails,
      companyData: authDetails.companyData,
      coupon: bookingDetails?.couponCode?.couponDetails,
    });
    _navToPaymentPage(payload);
  };

  const _handleSubmit = async () => {
    const isTncAccepted = _.has(authDetails.companyData?.member, "toc");
    if (!isTncAccepted) {
      return showToast.error(
        "Please accept the terms and privacy before proceeding!"
      );
    }
    if (bookingType === BOOKING_TYPES.CLASS) {
      if (isCamp) {
        //! no remaining concept
        campBooking();
        return;
      }
      classPackageBuy();
    }
    if (bookingType === BOOKING_TYPES.APPOINTMENT) {
      if (isCreditsAvailableSession(remainingSession)) {
        await sessionBook();
      } else {
        appointmentPackageBuy();
      }
    }
  };

  const sessionBook = async () => {
    setLoading({ isSubmitBtn: true });
    let payload = getSessionPayload({
      bookingDetails,
      companyData: authDetails.companyData,
    });
    payload = _.omit(payload, ["price", "packageLabel", "quantity"]);
    console.log("===========");
    console.log("payload", payload);
    console.log("===========");
    try {
      const response = await AxiosHelperPortal(
        BASE_URL_WEB + "add-appointment?",
        "post",
        payload
      );
      console.log("response", response);
      if (response.status === 200) {
        _moveToBookingConfirmationPage(response?.data);
      } else {
        showToast.error("Something went wrong!", "Sorry!");
        setTimeout(() => {
          history.replace("/class-booking", {
            start: payload?.start,
          });
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      showToast.error(error.response?.data?.message, "Sorry!");
      setTimeout(() => {
        history.replace("/class-booking", {
          start: payload?.start,
        });
      }, 3000);
    }
  };

  const _moveToBookingConfirmationPage = (responseData) => {
    console.log("responseDataasad", responseData);
    // dispatch(authCompanyData(authDetails.authDetails.username));
    dispatch(clearAppointmentBookingDetails());
    const bookingConfirmationPageData = getBookingConfirmationPageData({
      order: responseData?.order,
      service:
        bookingType === BOOKING_TYPES.APPOINTMENT
          ? responseData?.appointment
          : responseData?.booked_appointment,
      subscription: responseData?.subscription,
      member: authDetails?.companyData?.member,
      membership_pack: responseData?.membership_packs,
      ptRemaining: responseData?.data?.ptRemaining,
      doNotShowOrder: responseData?.doNotShowOrder,
      bookedWithCredits: responseData?.bookedWithCredits,
      isUnlimited: responseData?.unlimited,
      isFromCheckIn,
    });
    history.replace("/booking-confirmation", bookingConfirmationPageData);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container">
            <div className="_cardTop-hdr mb-4 text-center page-title">
              <h2 className="_text-dark">Confirm Booking</h2>
            </div>
            {bookingType === BOOKING_TYPES.APPOINTMENT ? (
              <BookingSteps step={4} />
            ) : (
              <CampSteps current={3} />
            )}
            <div className="_sessionBookingPage mt-2">
              <div className="container">
                <div className="row align-items-center">
                  <div className="booking_page">
                    <div className="_bookingTable-outter _list-outter">
                      <TrainerDetails
                        trainerDetails={bookingDetails?.trainer}
                      />
                      {!isCamp && (
                        <InfoItem
                          title="Remaining Session"
                          desc={
                            remainingSession?.isQuantityUnlimited
                              ? "Unlimited"
                              : remainingSession?.count
                          }
                        />
                      )}
                      <InfoItem
                        title="Service"
                        desc={
                          bookingType === BOOKING_TYPES.APPOINTMENT
                            ? bookingDetails.service?.serviceName
                            : bookingDetails?.classDetails?.serviceName
                        }
                      />
                      {/* //! remaining membership */}
                      <>
                        {remainingSession?.membership_pack != null && (
                          <div>
                            <div>
                              <label className="form-label">
                                Membership Pack :{" "}
                                <b>{remainingSession?.membership_pack.name}</b>
                              </label>
                            </div>
                            {remainingSession?.membership_pack
                              .subscription_status == "PENDING" && (
                              <div>
                                <div className="alert alert-warning">
                                  The Subscription status of this membership is
                                  pending. Subscription starts on{" "}
                                  <strong>
                                    {
                                      remainingSession?.membership_pack
                                        .subscription_start_date
                                    }
                                  </strong>
                                  .
                                </div>
                              </div>
                            )}
                            {remainingSession?.membership_pack.nextResetDate !=
                              null &&
                              remainingSession?.membership_pack
                                .total_quantity != null && (
                                <div>
                                  <div>
                                    <strong>
                                      Session reset cycle:{" "}
                                      {
                                        remainingSession?.membership_pack
                                          .total_quantity
                                      }{" "}
                                      sessions
                                    </strong>{" "}
                                    are added &nbsp;
                                    <strong>
                                      {FrequencyHelper(
                                        remainingSession?.membership_pack
                                          .resetFrequency
                                      )}
                                    </strong>
                                    , to your remaining sessions
                                  </div>
                                  <div>
                                    <strong>Next session reset: </strong>
                                    {
                                      remainingSession?.membership_pack
                                        .nextResetDate
                                    }
                                  </div>
                                  {remainingSession?.membership_pack
                                    ?.rollover === null && (
                                    <div>
                                      Unused sessions will be rolled over to the{" "}
                                      <strong>
                                        {FrequencyHelper(
                                          remainingSession?.membership_pack
                                            .resetFrequency,
                                          2
                                        )}
                                      </strong>
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      </>
                      {/* //! remaining membership */}
                      {/* //! show booking FMs | appointment #start */}
                      {bookingType === BOOKING_TYPES.APPOINTMENT && (
                        <InfoItem
                          title="Booked Family Member"
                          desc={
                            <CalendarFms
                              data={getSessionFm({
                                selectedFmUuidSession:
                                  bookingDetails?.selectedFmUuidSession,
                                compFm: authDetails.companyData?.familyMembers,
                                member: authDetails.companyData?.member,
                              })}
                              isCalendar={false}
                            />
                          }
                        />
                      )}

                      {/* //! show booking FMs | appointment #end */}
                      <BookingDateTimeUI
                        bookingType={bookingType}
                        bookingDetails={bookingDetails}
                        repeatIdClasses={repeatIdClasses}
                        isRepeatClass={isRepeatClass}
                      />
                      <br />
                      {bookingType === BOOKING_TYPES.CLASS && haveKids && (
                        <FamilyMembers
                          isCamp={isCamp}
                          isBookedIsCampNoBooking={isBookedIsCampNoBooking}
                          isClassFull={bookingDetails?.classDetails?.isClassFull}
                        />
                      )}
                      {bookingType === BOOKING_TYPES.CLASS &&
                        isCamp &&
                        haveKids &&
                        Boolean(
                          bookingDetails?.selectedFamilyMembers?.length
                        ) && <CampQuestions campQuestions={campQuestions} />}
                      {/* //! packs ui todo */}
                      {isCamp && (
                        <>
                          <RepeatClassesUI
                            repeatIdClasses={repeatIdClasses}
                            repeatId={bookingDetails?.classDetails?.repeat_id}
                          />
                          <CampPacks
                            selectedPackage={selectedPack}
                            campPacksList={campPacksList({ bookingDetails })}
                          />
                        </>
                      )}
                      {/* //! for normal class/appointment packs */}
                      {((bookingType === BOOKING_TYPES.APPOINTMENT &&
                        remainingSession?.showPackage) ||
                        (bookingType === BOOKING_TYPES.CLASS &&
                          !isCamp &&
                          remainingSession?.showPackage &&
                          calculateIsPackageNeeded({
                            remainingSession,
                            bookingDetails,
                          }))) && (
                        <>
                          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
                            Please select a package from below.
                          </p>
                          {allClassList?.map((item, index) => (
                            <section key={item?.uuid}>
                              {item?.packItems?.length ||
                              item?.servicePackages?.length ? (
                                <>
                                  <label
                                    key={index}
                                    className="text-center d-block border-secondary text-dark"
                                    htmlFor="formGroupExampleInput"
                                  >
                                    {item?.name && (
                                      <span className="d-inline-block border py-2 px-4 rounded-pill border-dark">
                                        {item?.name}
                                      </span>
                                    )}
                                  </label>
                                  <p className="mb-3 text-muted fw-normal fs-6 text-center">
                                    {item?.subDescription}
                                  </p>
                                </>
                              ) : null}
                              <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
                                {(item?.typename === "multi_class_pack"
                                  ? item?.packItems
                                  : item?.servicePackages
                                )
                                  ?.sort((a, b) =>
                                    //! sort the packages based on label
                                    a?.label?.localeCompare(b?.label)
                                  )
                                  ?.map((serviceP) => (
                                    <PackUI
                                      {...serviceP}
                                      key={serviceP?.label}
                                      isSelected={_.isEqual(
                                        selectedPack,
                                        serviceP
                                      )}
                                      coupon={
                                        bookingDetails?.couponCode
                                          ?.couponDetails
                                      }
                                      _handleOnClick={() => {
                                        dispatch(addSelectedPackage(serviceP));
                                        setMultiClassId(
                                          item?.typename === "multi_class_pack"
                                            ? item?.uuid
                                            : null
                                        );
                                      }}
                                    />
                                  ))}
                              </div>
                            </section>
                          ))}
                        </>
                      )}
                      {((bookingType === BOOKING_TYPES.APPOINTMENT &&
                        remainingSession?.showPackage) ||
                        (bookingType === BOOKING_TYPES.CLASS &&
                          remainingSession?.showPackage &&
                          calculateIsPackageNeeded({
                            remainingSession,
                            bookingDetails,
                          })) ||
                        isCamp) && (
                        <>
                          <br />
                          <CouponCodeUI
                            couponCodeLocalState={couponCode}
                            setCouponCode={setCouponCode}
                            _handleValidateCouponCode={
                              _handleValidateCouponCode
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="_btnGrp text-center py-4">
                    <button
                      className="btn btn-outline-danger px-md-5"
                      onClick={_handleOnClickBack}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary px-5"
                      disabled={isSubmitDisabled({
                        remainingSession,
                        bookingType,
                        bookingDetails,
                        selectedPack,
                        loading,
                        isPrivateClass,
                      })}
                      onClick={_handleSubmit}
                    >
                      {loading.isSubmitBtn  ? "Loading..." : "Confirm Booking"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmBooking;
